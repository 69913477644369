import Glide from "@glidejs/glide";

function runOnStart() {
		  try {
    const glide = new Glide(document.querySelector(".glider"), {
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: true,
      autoplay: 2500,
      arrows: {
        prev: ".glider-prev",
        next: ".glider-next",
      },
      // responsive: [
      //   {
      //     // screens greater than >= 775px
      //     breakpoint: 775,
      //     settings: {
      //       // Set to `auto` and provide item width to adjust to viewport
      //       slidesToShow: "auto",
      //       slidesToScroll: "auto",
      //       itemWidth: 150,
      //       duration: 0.25,
      //     },
      //   },
      //   {
      //     // screens greater than >= 1024px
      //     breakpoint: 1024,
      //     settings: {
      //       slidesToShow: 2,
      //       slidesToScroll: 1,
      //       itemWidth: 150,
      //       duration: 0.25,
      //     },
      //   },
      // ],
    });
    glide.on(["run.after","mount.after"], function () {
      // Handler logic ...
      console.log("resise");
      window.dispatchEvent(new Event("resize"));
    });
    const track = document.getElementById("artistas");
    console.log("track", track.classList.toString());
    track.classList.remove("hide");
    console.log("track", track.classList);
    glide.mount();
  } catch (error) {
    console.log("Erro ao iniciar o glide", error);
  }
}

if(document.readyState !== 'loading') {
    runOnStart();
}
else {
    document.addEventListener('DOMContentLoaded', function () {
        runOnStart()
    });
}